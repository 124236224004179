// Generated by Framer (e2fa450)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Link, RichText, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const enabledGestures = {zWI4hX4Un: {hover: true}};

const serializationHash = "framer-5fVOH"

const variantClassNames = {zWI4hX4Un: "framer-v-1uo9avs"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({height, id, link, title, width, ...props}) => { return {...props, a4YNK2IZ5: link ?? props.a4YNK2IZ5, gSiSy5u3Q: title ?? props.gSiSy5u3Q ?? "Marketing Agencies"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;title?: string;link?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, gSiSy5u3Q, a4YNK2IZ5, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({defaultVariant: "zWI4hX4Un", enabledGestures, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><Link href={a4YNK2IZ5} smoothScroll><motion.a {...restProps} {...gestureHandlers} className={`${cx(serializationHash, ...sharedStyleClassNames, "framer-1uo9avs", className, classNames)} framer-cn0ggt`} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"zWI4hX4Un"} ref={ref ?? ref1} style={{...style}} {...addPropertyOverrides({"zWI4hX4Un-hover": {"data-framer-name": undefined}}, baseVariant, gestureVariant)}><RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--font-selector": "R0Y7T3BlbiBTYW5zLXJlZ3VsYXI=", "--framer-font-family": "\"Open Sans\", \"Open Sans Placeholder\", sans-serif", "--framer-font-size": "18px", "--framer-line-height": "1.5em", "--framer-text-color": "var(--extracted-r6o4lv, var(--token-fd57fd7d-d817-4859-b6d3-85dea588cc05, rgba(255, 255, 255, 0.7)))"}}>Get Started</motion.p></React.Fragment>} className={"framer-1elo4np"} data-framer-name={"Get Started"} fonts={["GF;Open Sans-regular"]} layoutDependency={layoutDependency} layoutId={"ZoeErLJ_c"} style={{"--extracted-r6o4lv": "var(--token-fd57fd7d-d817-4859-b6d3-85dea588cc05, rgba(255, 255, 255, 0.7))", "--framer-paragraph-spacing": "0px"}} text={gSiSy5u3Q} variants={{"zWI4hX4Un-hover": {"--extracted-r6o4lv": "var(--token-712a0da0-9b04-4b4c-826c-8ec32ba098c1, rgb(255, 255, 255))"}}} verticalAlignment={"top"} withExternalLayout {...addPropertyOverrides({"zWI4hX4Un-hover": {children: <React.Fragment><motion.p style={{"--font-selector": "R0Y7T3BlbiBTYW5zLXJlZ3VsYXI=", "--framer-font-family": "\"Open Sans\", \"Open Sans Placeholder\", sans-serif", "--framer-font-size": "18px", "--framer-line-height": "1.5em", "--framer-text-color": "var(--extracted-r6o4lv, var(--token-712a0da0-9b04-4b4c-826c-8ec32ba098c1, rgb(255, 255, 255)))"}}>Get Started</motion.p></React.Fragment>}}, baseVariant, gestureVariant)}/></motion.a></Link></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-5fVOH.framer-cn0ggt, .framer-5fVOH .framer-cn0ggt { display: block; }", ".framer-5fVOH.framer-1uo9avs { align-content: center; align-items: center; cursor: pointer; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: min-content; justify-content: center; overflow: hidden; padding: 4px; position: relative; text-decoration: none; width: min-content; }", ".framer-5fVOH .framer-1elo4np { flex: none; height: auto; position: relative; white-space: pre; width: auto; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-5fVOH.framer-1uo9avs { gap: 0px; } .framer-5fVOH.framer-1uo9avs > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-5fVOH.framer-1uo9avs > :first-child { margin-left: 0px; } .framer-5fVOH.framer-1uo9avs > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 35
 * @framerIntrinsicWidth 172
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]},"ahIe4zCdP":{"layout":["auto","auto"]}}}
 * @framerVariables {"gSiSy5u3Q":"title","a4YNK2IZ5":"link"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerkOBATePX1: React.ComponentType<Props> = withCSS(Component, css, "framer-5fVOH") as typeof Component;
export default FramerkOBATePX1;

FramerkOBATePX1.displayName = "text link";

FramerkOBATePX1.defaultProps = {height: 35, width: 172};

addPropertyControls(FramerkOBATePX1, {gSiSy5u3Q: {defaultValue: "Marketing Agencies", displayTextArea: false, title: "Title", type: ControlType.String}, a4YNK2IZ5: {title: "Link", type: ControlType.Link}} as any)

addFonts(FramerkOBATePX1, [{explicitInter: true, fonts: [{family: "Open Sans", source: "google", style: "normal", url: "https://fonts.gstatic.com/s/opensans/v40/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsjZ0C4iY1M2xLER.woff2", weight: "400"}]}], {supportsExplicitInterCodegen: true})